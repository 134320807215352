<template>
  <div>
    <div v-if="allowCreate" class="d-flex justify-content-end align-items-center mb-2">
      <button class="btn btn-primary" @click="onClickNew">New {{ friendlyName }}</button>
    </div>
    <DataTable
      :items="items"
      :total="totalItems"
      :loading="loadingAction.list"
      :columns="columns"
      :page-size="20"
      :fetch-items="getItems"
      default-sort-key="createdAt"
      :default-sort-direction="-1"
      no-hover
    >
      <slot :data="data"></slot>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <div v-if="slot === 'actions'" :key="slot" class="d-flex">
          <button v-if="allowEdit" class="btn btn-sm btn-text-primary mr-2" @click="onClickEdit(scope.data)">
            <i class="fa fa-pencil mr-2"></i>Edit
          </button>
          <button v-if="allowCreate" class="btn btn-sm btn-text-danger" @click="modals.delete = scope.data">
            <i class="fa fa-trash mr-2"></i>Delete
          </button>
          <slot :name="slot" v-bind="scope" />
        </div>
        <slot v-else :name="slot" v-bind="scope" />
      </template>
    </DataTable>
    <Modal
      v-if="formComponent"
      :open="!!modals.edit"
      :title="friendlyName"
      text="Edit text..."
      lg-size
      :prevent="true"
      :loading="spinners.saving"
      confirm-text="Save"
      @close="modals.edit = false"
      @submit="onSave"
    >
      <component :is="formComponent" v-if="modals.edit" v-model="modals.edit.form" />
    </Modal>
    <Modal
      :open="!!modals.delete"
      title="Delete Item"
      :prevent="true"
      text="Are you sure you want to delete this item?"
      @close="modals.delete = false"
      @submit="onDelete"
    >
    </Modal>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable';
import Modal from '@/components/ConfirmModal';

export default {
  components: {
    DataTable,
    Modal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formComponent: null,
      modals: {
        create: null,
        delete: null,
        edit: null
      },
      spinners: {
        saving: false,
        deleting: false
      }
    };
  },
  computed: {
    friendlyName() {
      console.log(this.$store);
      return this.$store.getters[`${this.id}/friendlyName`];
    },
    items() {
      return this.$store.getters[`${this.id}/items`];
    },
    totalItems() {
      return this.$store.getters[`${this.id}/total`];
    },
    loadingAction() {
      return this.$store.getters[`${this.id}/loadingAction`];
    }
  },
  async beforeMount() {
    this.schema = await this.$store.dispatch(`${this.id}/schema`);

    const formComponentName = this.$store.getters[`${this.id}/formComponent`];
    if (formComponentName) {
      this.formComponent = () => import(`@/components/forms/${formComponentName}`);
    }
  },
  methods: {
    onClickNew() {
      const defaultFormFromSchema = this.schema.reduce((acc, schemaItem) => {
        acc[schemaItem.value] = schemaItem.default;
        return acc;
      }, {});

      this.modals.edit = {
        form: { ...defaultFormFromSchema }
      };
    },
    onClickEdit(item) {
      const formWithDefaults = this.schema.reduce((acc, schemaItem) => {
        const value = item[schemaItem.value];
        acc[schemaItem.value] = value != null ? value : schemaItem.default;
        return acc;
      }, {});

      this.modals.edit = {
        id: item._id,
        form: {
          ...formWithDefaults,
          _id: undefined,
          createdAt: undefined,
          updatedAt: undefined
        }
      };
    },
    async onDelete() {
      this.spinners.deleting = true;

      await this.$store.dispatch(`${this.id}/remove`, { id: this.modals.delete._id });

      this.spinners.deleting = false;
      this.modals.delete = null;

      await this.getItems();
    },
    async onSave() {
      this.spinners.saving = true;

      if (this.modals.edit.id) {
        await this.$store.dispatch(`${this.id}/update`, { id: this.modals.edit.id, data: this.modals.edit.form });
      } else {
        await this.$store.dispatch(`${this.id}/create`, { data: this.modals.edit.form });
      }

      this.spinners.saving = false;
      this.modals.edit = null;

      await this.getItems();
    },
    async getItems() {
      return this.$store.dispatch(`${this.id}/list`);
    }
  }
};
</script>
