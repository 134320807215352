var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ResourceList', {
    attrs: {
      "id": "kiosk",
      "columns": _vm.columns,
      "allow-create": true,
      "allow-edit": true
    },
    scopedSlots: _vm._u([{
      key: "createdAt",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_vm._v(" " + _vm._s(_vm._f("date")(data.createdAt, 'DD MMM YYYY HH:mm:ss')) + " ")];
      }
    }, {
      key: "assetId",
      fn: function fn(_ref2) {
        var _vm$assets, _vm$assets$find;

        var data = _ref2.data;
        return [_vm._v(" " + _vm._s(((_vm$assets = _vm.assets) === null || _vm$assets === void 0 ? void 0 : (_vm$assets$find = _vm$assets.find(function (a) {
          return a._id === data.assetId;
        })) === null || _vm$assets$find === void 0 ? void 0 : _vm$assets$find.siteName) || 'N/A') + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var data = _ref3.data;
        return [_c('button', {
          staticClass: "btn btn-sm btn-text-secondary",
          on: {
            "click": function click($event) {
              return _vm.onClickPreview(data._id);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-tv mr-2"
        }), _vm._v(" Preview ")]), _c('button', {
          staticClass: "btn btn-sm btn-text-secondary",
          on: {
            "click": function click($event) {
              return _vm.onClickPairModal(data._id);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-rotate mr-2"
        }), !data.pairingCode ? _c('span', [_vm._v("Pair")]) : _c('span', [_vm._v("Re-Pair")])])];
      }
    }])
  }), _c('Modal', {
    attrs: {
      "open": _vm.pairModal.show,
      "title": "Pair Kiosk",
      "text": "Are you sure you want to pair this kiosk?",
      "prevent": true,
      "loading": _vm.pairModal.loading
    },
    on: {
      "submit": _vm.onPair,
      "close": _vm.onClickClose
    }
  }, [_c('FormGroup', {
    attrs: {
      "id": "pairingCode",
      "type": "text",
      "label": "Pairing Code",
      "description": "After installing the Optisigns app on your device, enter the displayed pairing code here",
      "placeholder": "Enter a pairing code"
    },
    model: {
      value: _vm.pairModal.pairingCode,
      callback: function callback($$v) {
        _vm.$set(_vm.pairModal, "pairingCode", $$v);
      },
      expression: "pairModal.pairingCode"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }