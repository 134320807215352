<template>
  <div>
    <ResourceList id="kiosk" :columns="columns" :allow-create="true" :allow-edit="true">
      <template v-slot:createdAt="{ data }">
        {{ data.createdAt | date('DD MMM YYYY HH:mm:ss') }}
      </template>
      <template v-slot:assetId="{ data }">
        {{ assets?.find(a => a._id === data.assetId)?.siteName || 'N/A' }}
      </template>
      <template v-slot:actions="{ data }">
        <button class="btn btn-sm btn-text-secondary" @click="onClickPreview(data._id)">
          <i class="fa fa-tv mr-2"></i>
          Preview
        </button>
        <button class="btn btn-sm btn-text-secondary" @click="onClickPairModal(data._id)">
          <i class="fa fa-rotate mr-2"></i>
          <span v-if="!data.pairingCode">Pair</span>
          <span v-else>Re-Pair</span>
        </button>
      </template>
    </ResourceList>
    <Modal
      :open="pairModal.show"
      title="Pair Kiosk"
      text="Are you sure you want to pair this kiosk?"
      :prevent="true"
      :loading="pairModal.loading"
      @submit="onPair"
      @close="onClickClose"
    >
      <FormGroup
        id="pairingCode"
        v-model="pairModal.pairingCode"
        type="text"
        label="Pairing Code"
        description="After installing the Optisigns app on your device, enter the displayed pairing code here"
        placeholder="Enter a pairing code"
      >
      </FormGroup>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';

import ResourceList from '@/components/resource/List';

export default {
  components: {
    FormGroup,
    Modal,
    ResourceList
  },
  data() {
    return {
      pairModal: {
        show: false,
        kioskId: null,
        pairingCode: '',
        loading: false
      },
      columns: [
        { code: 'name', name: 'Name', sortable: true },
        { code: 'optisignsDeviceId', name: 'Optisigns Device ID' },
        { code: 'optisignsAssetId', name: 'Optisigns Asset ID' },
        { code: 'pairingCode', name: 'Last Pairing Code' },
        { code: 'assetId', name: 'Site Name' },
        { code: 'actions', name: 'Actions' }
      ],
      assets: []
    };
  },
  async mounted() {
    const assets = await this.$store.dispatch('asset/list', { data: { params: { $limit: 10000, $select: '_id,siteName' } } });
    this.assets = assets.data;
  },
  methods: {
    onClickPairModal(kioskId) {
      this.pairModal.show = true;
      this.pairModal.kioskId = kioskId;
    },
    async onPair() {
      console.log('Pairing kiosk', this.pairModal.kioskId, 'with code', this.pairModal.pairingCode);

      this.pairModal.loading = true;

      await this.$store.dispatch('kiosk/pairOptisignsScreen', {
        kioskId: this.pairModal.kioskId,
        pairingCode: this.pairModal.pairingCode
      });

      this.pairModal = {
        show: false,
        kiosk: {},
        pairingCode: '',
        loading: false
      };

      this.$toasted.success('Kiosk paired successfully');

      await this.$store.dispatch('kiosk/list', { data: { params: { $limit: 10000 } } });
    },
    onClickClose() {
      this.pairModal = {
        show: false,
        kiosk: {},
        pairingCode: '',
        loading: false
      };
    },
    async onClickPreview(kioskId) {
      const previewUrl = await this.$store.dispatch('kiosk/getPreviewUrl', { id: kioskId });

      window.open(previewUrl.url, '_blank');
    }
  }
};
</script>
